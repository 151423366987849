import moment from 'moment';
import {
  get,
  keyBy,
} from 'lodash';
import {
  ELSAdobeAnalyticService,
  ELSLoggingService,
  ELSCommonUIConstants,
  ELSCommonConfig
} from '@els/els-ui-common-react';
import axios, { AxiosResponse } from 'axios';
import {
  fetchAssessmentSubmissions as fetchAssessmentSubmissionsService,
  fetchCatalog as fetchCatalogService,
  fetchCourseCopyPreview as fetchCourseCopyPreviewService,
  fetchMigratedEntitlements,
  fetchNewTokenForCourseSwitcher,
  fetchOsmosisToken,
  fetchSkillPerformance,
  fetchSyllabusItem,
  fetchSyllabusItemsV3,
  fetchUsers as fetchUsersService,
  fetchVitalSource as fetchVitalSourceService,
  postCourseCopy as postCourseCopyService,
  postSyllabusAssignment as postSyllabusAssignmentService,
  postSyllabusItem as postSyllabusItemService,
  putMigrateSyllabusItems,
  putSyllabusAssignment as putSyllabusAssignmentService,
  putSyllabusItem,
  putSyllabusItems as putSyllabusItemsService,
  postSyllabusItems as postSyllabusItemsService
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.utilities';
import {
  fetchAppLinkData as fetchAppLinkDataService,
  fetchHashLink,
  getExternalAppRedirectUrl,
  getLinkNavigation,
} from '../../apis/eols-app-link/eols-app-link.utilities';
import {
  fetchCourseSection as fetchCoursePlanningCourseSectionService,
  updateCourseSection as updateCourseSectionService,
} from '../../apis/eols-course-management-service/eols-course-management-service.utilities';
import {
  fetchCourseSection as fetchCourseSectionService,
  fetchUserCourseSections,
} from '../../apis/eols-course-crud/eols-course-crud.utilities';
import { reduxStateDomains } from '../redux.constants';
import {
  AccessTokenRequestDto,
  CatalogEvolveResourceExternalEntityDtoParsed,
  CatalogWithExternalEntitiesDto,
  CourseCopyPreviewDto,
  CourseManagementSyllabusItemsDto,
  NewTokenDto,
  SyllabusAssignmentDto,
  UserDto,
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  AppLinkData,
  AppLinkReturnPostBody,
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { LiteCourseSectionDto } from '../../apis/eols-course-management-service/eols-course-management-service.dtos';
import {
  fetchCrosswalkUser as fetchCrosswalkUserService,
  fetchUser as fetchUserService
} from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import {
  fetchGroupedFeatureFlags,
  fetchGroupFeatureFlag,
  postGroupFeatureFlag,
  putGroupFeatureFlag
} from '../../apis/eols-features-api/eols-features-api.utilities';
import {
  deleteUserHistoryByUserId,
  fetchUserHistoryByCourseByStateKey as fetchUserHistoryByCourseByStateKeyService,
  postUserHistory as postUserHistoryService,
} from '../../apis/eols-user-crud/eols-user-crud.utilities';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  CoursewareUserHistoryStateKey,
  CoursewareUserHistoryStateKeyRoleMap,
  EolsUserHistoryDto,
  EolsUserHistoryResponseDto,
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import { cwSelectors } from './courseware.selectors';
import {
  RecContentItemTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import { fetchPrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.utilities';
import { fetchTaxonomy as fetchTaxonomyService } from '../../apis/eols-taxonomy/eols-taxonomy.utilities';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyDto
} from '../../apis/rec-gateway/rec-gateway.models';
import { coursewareInitialState } from './courseware.constants';
import {
  AdobeAnalyticsAction,
  AdobeAnalyticsActionProps,
  CourseBuilderStore,
  CoursewareStore,
  NavigateToApp,
  ReduxPageWithCatalog,
  ReduxPageWithFilter,
  ReduxPageWithPrimaryTaxonomies,
} from './courseware.models';
import {
  AssessmentDto,
  AssessmentStatusDto,
  AssessmentSubmissionDto,
  AssignmentDto,
  SessionEvent,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  fetchAssessmentByAssignmentIdAndUserId as fetchAssessmentByAssignmentIdAndUserIdService,
  fetchAssessmentsByAssignmentIdAndUserId,
  fetchAssignment,
  fetchAssignmentResults,
  fetchAssignments as fetchAssignmentsService,
  postCreateAssessmentFromAssignment as postCreateAssessmentFromAssignmentService,
  postProvisionStudentAssignmentData,
  putAssignment as putAssignmentService,
  submitAssessmentScore,
} from '../../apis/eols-assessment-service/eols-assessment-service.utilities';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ActiveSyllabusItemTypeDto, } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { SystemType } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import {
  fetchEvolveResource,
  fetchLearningObjectLessonAndModule,
  fetchLessonTopics,
  fetchResourceInfo,
  fetchSkillStaticData as fetchSkillStaticDataService
} from '../../apis/ocs-api-service/ocs-api-service.utilities';
import {
  EbookFilterChangeProps,
  EbookFilterField,
  EbookFilterState
} from '../../components/ebook-filter/ebook-filter.models';
import {
  makeGetCatalogInReduxPage,
  makeGetPrimaryTaxonomiesInReduxPage
} from './courseware.selectorHof';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { AppLinkOutConfig } from '../../apis/eols-app-link/eols-app-link.models';
import { fetchUserEngagementReport as fetchUserEngagementReportService } from '../../apis/eols-user-engagement/eols-user-engagement.utilities';
import { EolsUserEngagementDto } from '../../apis/eols-user-engagement/eols-user-engagement.dtos';
import { Messages } from '../../translations/message.models';
import {
  GroupActivityDto,
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import en from '../../translations/en';
import {
  AnalyticsAction,
  AnalyticsActionProps,
  AnalyticsFilterType
} from '../../models/analytics.models';
import { getBeforeAfterDueDateDue } from '../../utilities/adobe-analytics-utilities';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import {
  stripNilProps,
  isInstructor
} from '../../utilities/common.utilities';
import { fetchAuthessHealthCheck as fetchAuthessHealthCheckService } from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import {
  fetchDeepLinkData,
  postDeepLink
} from '../../apis/eols-lms-config-service/eols-lms-config-service.utilities';
import { DeepLinkPostDto } from '../../apis/eols-lms-config-service/eols-lms-config-service.dtos';
import { fetchRelatedHesiCourses } from '../../apis/hesi-app-facade-service/hesi-app-facade-service.utilities';
import {
  getEvolveToken,
  getShadowHealthRelatedCourses,
  goToShadow
} from '../../apis/evolve-user-management-client/evolve-user-management-client.utilities';
import { ShadowHealthCourseDto } from '../../apis/evolve-user-management-client/evolve-user-management-client.dtos';
import {
  fetchEvolveProducts,
  fetchSherpathClassicGradeExport,
  fetchSherpathSchedules
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.utilities';
import { postLearningContext } from '../../apis/eols-learning-content-service/eols-learning-content-service.utilities';
import { LearningContextDto } from '../../apis/eols-learning-content-service/eols-learning-content-service.dtos';
import { fetchAnalyticsContext } from '../../apis/learning-analytics-service/learning-analytics-service.utilities';
import { LearningAnalyticsRequestDto } from '../../apis/learning-analytics-service/learning-analytics-service.dtos';
import {
  getAssessmentSubmissionsMap,
  getModuleSequenceMapFromCatalog,
  getParsedDataMap,
  shouldGetNewOsmosisToken
} from '../redux.utilities';
import { fetchOsmosisTranscript } from '../../apis/osmosis-service/osmosis-service.utilities';
import { postGetSupportTicketUrl } from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.utilities';
import { SupportTicketPostBodyDto } from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.dtos';
import {
  getActiveABTestFlags,
  getGroupFeatureFlagWithFallbackToGlobal,
  getRandomABTestFlavor,
} from '../../utilities/featureFlag.utilities';
import { SyllabusFolderLocationInfo } from '../../pages/catalog/catalog.models';
import { ServerConstants } from '../../components/app/server.constants';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../../apis/eols-features-api/eols-features-api.dtos';
import {
  COURSE_SETTINGS_FEATURE_FLAG,
  FEATURE_FLAG,
  SHER_EVOL_COURSE_SETTINGS,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import { AppConstants } from '../../components/app/app.constants';
import { isInIframe } from '../../utilities/app.utilities';
import { SyllabusItemDictionary } from '../../pages/course-plan/syllabus.utilities';
import { ClinicalSkillsFilterChangeProps } from '../../components/clinical-skills-filter/clinical-skills-filter.models';

const fileName = 'courseware.actions';

export interface UserParams {
  userId: string;
  courseId: string;
  roleId: string;
  isbns: string;
}

export const coursewareActionType = {
  DELETE_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/DELETE_USER_HISTORY_SUCCESS`,
  FETCH_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_HISTORY_SUCCESS`,
  FETCH_COURSE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_COURSE_SUCCESS`,
  FETCH_GROUPED_FEATURE_FLAGS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_GROUPED_FEATURE_FLAGS_SUCCESS`,
  FETCH_COURSE_SETTINGS_FEATURE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_COURSE_SETTINGS_FEATURE_SUCCESS`,
  ADD_FEATURE_FLAG: `${reduxStateDomains.COURSEWARE_STATE}/ADD_FEATURE_FLAG`,
  FETCH_FEATURE_ISBNS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_FEATURE_ISBNS`,
  FETCH_LINK_DATA_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_LINK_DATA_SUCCESS`,
  FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS`,
  FETCH_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_SUCCESS`,
  FETCH_USERS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USERS_SUCCESS`,
  POST_COURSE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_COURSE_SUCCESS`,
  REQUEST_ERROR: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_ERROR`,
  REQUEST_START: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_START`,
  REQUEST_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_SUCCESS`,
  RESET_STORE: `${reduxStateDomains.COURSEWARE_STATE}/RESET_STORE`,
  RESET_STATE_ON_LAUNCH: `${reduxStateDomains.COURSEWARE_STATE}/RESET_STATE_ON_LAUNCH`,
  RESTORE_STATE: `${reduxStateDomains.COURSEWARE_STATE}/RESTORE_STATE`,
  SET_IS_COURSE_OWNER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_COURSE_OWNER_SUCCESS`,
  SET_ISBNS: `${reduxStateDomains.COURSEWARE_STATE}/SET_ISBNS`,
  SET_MESSAGES: `${reduxStateDomains.COURSEWARE_STATE}/SET_MESSAGES`,
  SET_USER: `${reduxStateDomains.COURSEWARE_STATE}/SET_USER`,
  SET_REGISTERED_TOKEN: `${reduxStateDomains.COURSEWARE_STATE}/SET_REGISTERED_TOKEN`,
  FETCH_CATALOG_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_CATALOG_SUCCESS`,
  FETCH_PRIMARY_TAXONOMY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_PRIMARY_TAXONOMY_SUCCESS`,
  FETCH_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSIGNMENT_SUCCESS`,
  DELETE_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/DELETE_SYLLABUS_ITEMS_SUCCESS`,
  FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ALL_EVOLVE_INSTRUCTOR_RESOURCE_DATA_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ALL_EVOLVE_INSTRUCTOR_RESOURCE_DATA_SUCCESS`,
  FETCH_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSIGNMENTS_SUCCESS`,
  FETCH_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SYLLABUS_ITEMS_SUCCESS`,
  POST_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_SYLLABUS_ASSIGNMENT_SUCCESS`,
  POST_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_SYLLABUS_ITEMS_SUCCESS`,
  PUT_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_SYLLABUS_ASSIGNMENT_SUCCESS`,
  PUT_SYLLABUS_ITEMS_BATCH_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_SYLLABUS_ITEMS_BATCH_SUCCESS`,
  POST_SYLLABUS_ITEMS_BATCH_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_SYLLABUS_ITEMS_BATCH_SUCCESS`,
  PUT_SYLLABUS_ITEM_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_SYLLABUS_ITEM_SUCCESS`,
  PUT_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_ASSIGNMENTS_SUCCESS`,
  SET_CHECKED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_CHECKED_SYLLABUS_ITEMS`,
  SET_SYLLABUS_FOLDER_INFO: `${reduxStateDomains.COURSEWARE_STATE}/SET_SYLLABUS_FOLDER_INFO`,
  SET_IS_BATCH_EDIT_MODE: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_BATCH_EDIT_MODE`,
  SET_IS_DRAG_DROP_MODE: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_DRAG_DROP_MODE`,
  UPDATE_COURSE_BUILDER_STATE: `${reduxStateDomains.COURSEWARE_STATE}/UPDATE_COURSE_BUILDER_STATE`,
  FETCH_EOLS_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EOLS_USER_SUCCESS`,
  FETCH_CROSSWALK_USERS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_CROSSWALK_USERS_SUCCESS`,
  FETCH_EVOLVE_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EVOLVE_USER_SUCCESS`,
  POST_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_USER_HISTORY_SUCCESS`,
  SET_EBOOK_FILTER: `${reduxStateDomains.COURSEWARE_STATE}/SET_EBOOK_FILTER`,
  HANDLE_EBOOK_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_EBOOK_FILTER_CHANGE`,
  HANDLE_CLINICAL_SKILLS_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_CLINICAL_SKILLS_FILTER_CHANGE`,
  HANDLE_PRODUCT_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_PRODUCT_FILTER_CHANGE`,
  HANDLE_RESOURCE_STATUS_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_STATUS_FILTER_CHANGE`,
  HANDLE_RESOURCE_TYPE_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_TYPE_FILTER_CHANGE`,
  HANDLE_RESOURCE_GRADING_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_GRADING_FILTER_CHANGE`,
  SET_ASSESSMENT_START_TIME_MAP: `${reduxStateDomains.COURSEWARE_STATE}/SET_ASSESSMENT_START_TIME_MAP`,
  SET_APP_LINK_COOKIES: `${reduxStateDomains.COURSEWARE_STATE}/SET_APP_LINK_COOKIES`,
  UPDATE_COLLAPSED_FOLDER_IDS: `${reduxStateDomains.COURSEWARE_STATE}/UPDATE_COLLAPSED_FOLDER_IDS`,
  FETCH_SKILL_STATIC_DATA: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SKILL_STATIC_DATA`,
  FETCH_SKILL_SUBMISSION_RECORD: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SKILL_SUBMISSION_RECORD`,
  SET_STORE_PROPS: `${reduxStateDomains.COURSEWARE_STATE}/SET_STORE_PROPS`,
  FETCH_COURSE_COPY_PREVIEW_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_COURSE_COPY_PREVIEW_SUCCESS`,
  SET_HESI_FOCUS_CHAPTER_FILTER: `${reduxStateDomains.COURSEWARE_STATE}/SET_HESI_FOCUS_CHAPTER_FILTER`,
  SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS`,
  SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS`,
  FETCH_USER_ENGAGEMENT_REPORT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_ENGAGEMENT_REPORT_SUCCESS`,
  CLEAR_ALL_FILTERS: `${reduxStateDomains.COURSEWARE_STATE}/CLEAR_ALL_FILTERS`,
  POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS`,
  FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID`,
  FETCH_GROUP_ACTIVITY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_GROUP_ACTIVITY_SUCCESS`,
  SET_ENABLE_DEEP_LINK: `${reduxStateDomains.COURSEWARE_STATE}/SET_ENABLE_DEEP_LINK`,
  SET_HAS_RUN_AUTHESS_HEALTH_CHECK: `${reduxStateDomains.COURSEWARE_STATE}/SET_HAS_RUN_AUTHESS_HEALTH_CHECK`,
  FETCH_OSMOSIS_TOKEN_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_OSMOSIS_TOKEN_SUCCESS`,
  FETCH_MIGRATED_ENTITLEMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_MIGRATED_ENTITLEMENTS_SUCCESS`,
  FETCH_AB_TEST_FLAVOR_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_AB_TEST_FLAVOR_SUCCESS`,
  SET_PROGRESS_INDICATOR_VALUES: `${reduxStateDomains.COURSEWARE_STATE}/SET_PROGRESS_INDICATOR_VALUES`,
  INCREMENT_PROGRESS_INDICATOR: `${reduxStateDomains.COURSEWARE_STATE}/INCREMENT_PROGRESS_INDICATOR`,
  SET_IS_APP_LINK_ASSIGNMENT_CREATE_FLOW: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_APP_LINK_ASSIGNMENT_CREATE_FLOW`,
  SET_APP_LINK_ASSIGNMENT_STUDENTS: `${reduxStateDomains.COURSEWARE_STATE}/SET_APP_LINK_ASSIGNMENT_STUDENTS`,
  UPDATE_COLLAPSED_FILTER_TITLES: `${reduxStateDomains.COURSEWARE_STATE}/UPDATE_COLLAPSED_FILTER_TITLES`
};

const restoreState = (state) => ({ type: coursewareActionType.RESTORE_STATE, payload: state });

export const resetState = () => (dispatch) => {
  dispatch({
    type: coursewareActionType.RESET_STORE,
    payload: null
  });
};

export const resetStateOnLaunch = () => (dispatch) => {
  dispatch({
    type: coursewareActionType.RESET_STATE_ON_LAUNCH,
    payload: null
  });
};

const trackAction = (props: AnalyticsActionProps) => (dispatch, getState) => {
  const defaultActionProps = cwSelectors.getDefaultActionProps(getState());
  const actionProps = scrubProps(props.props);
  const _props = {
    ...defaultActionProps,
    ...actionProps
  };
  ELSLoggingService.info(fileName, `New Relic PageAction: ${props.action}, ${JSON.stringify(_props)}`);
  if (!window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(props.action, _props);
};

const trackAdobeAction = (props: AdobeAnalyticsActionProps) => (dispatch, getState) => {
  const defaultPageData = cwSelectors.getDefaultAdobeAnalyticsPageData(getState());
  const educationProps = stripNilProps(props.pageData.education);
  const pageData = {
    ...defaultPageData,
    education: {
      ...defaultPageData.education,
      ...educationProps
    }
  };
  ELSAdobeAnalyticService.trackEvent(props.action, pageData);
};

const handleRequestError = (dispatch, getState) => (
  error: Error,
  action: string,
  isTrackRequestCount = true
) => {
  const url = get(error, 'config.url', '');
  const method = get(error, 'config.method', '');
  const status = get(error, 'response.status', '');
  if (status === 400) {
    // Likely 400 error here due to overloaded headers
    removeCookies();
  }

  trackAction({
    action: AnalyticsAction.API_ERROR,
    props: {
      status,
      action,
      url,
      method
    }
  })(dispatch, getState);

  dispatch({
    type: coursewareActionType.REQUEST_ERROR,
    payload: {
      error,
      isTrackRequestCount
    },
  });
  return Promise.reject(error);
};

const fetchCourseSection = (
  courseSectionId: string,
  useCache = false
) => (dispatch, getState): Promise<CourseSectionDto> => {
  if (useCache) {
    const courseSection = cwSelectors.getCourse(getState());
    if (courseSection) {
      return Promise.resolve(courseSection);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START, });
  return fetchCourseSectionService(courseSectionId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_COURSE_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseSection'));
};

const fetchAssessmentsByAssignmentIdAndUserIdAction = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentsByAssignmentIdAndUserId(userId, assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentsByAssignmentIdAndUserIdAction'));
};

const fetchRecentAssessmentOfAssignment = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentByAssignmentIdAndUserIdService(userId, assignmentId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRecentAssessmentOfAssignment'));
};

const fetchUsers = (courseSectionIds: string[], roleId: string, userId: string, useCache = false) => (dispatch, getState): Promise<UserDto[]> => {

  if (useCache) {
    const users = cwSelectors.getUsers(getState());
    if (users && users.length > 0) {
      return Promise.resolve(users);
    }
  }

  dispatch({ type: coursewareActionType.REQUEST_START, });
  if (roleId === ELSCommonUIConstants.userType.Instructor) {
    return fetchUsersService(courseSectionIds)
      .then((users: UserDto[]) => {
        dispatch({
          type: coursewareActionType.FETCH_USERS_SUCCESS,
          payload: users,
        });
        return users;
      })
      .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));
  }

  return fetchUserService(userId)
    .then((user: UserDto) => {
      dispatch({
        type: coursewareActionType.FETCH_USERS_SUCCESS,
        payload: [user],
      });
      return [user];
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));

};

const getUpdatedPropsOnly = (existingCourse: LiteCourseSectionDto, propsToUpdate: Partial<LiteCourseSectionDto>): Partial<LiteCourseSectionDto> => {
  const ret = { ...propsToUpdate };
  Object.keys(propsToUpdate).forEach((key) => {
    if (existingCourse[key] === propsToUpdate[key]) {
      delete ret[key];
    }
  });
  return ret;
};

const updateCourseSection = (
  courseSectionId: string,
  updatedCourseInfo: Partial<LiteCourseSectionDto>,
) => (dispatch, getState): Promise<LiteCourseSectionDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCoursePlanningCourseSectionService(courseSectionId)
    .then(response => {
      const { etag } = response.headers as { etag: string };
      const _updatedCourseInfo = getUpdatedPropsOnly(response.data, updatedCourseInfo);
      return updateCourseSectionService(courseSectionId, _updatedCourseInfo, etag).then((data) => {
        dispatch({
          type: coursewareActionType.POST_COURSE_SUCCESS,
          payload: data,
        });
        return data;
      });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'updateCourseSection'));
};

// In the future we should consider retrieving this value from the token always
// For pilot and for development we need to separate these values from the token
// If we can reliably get pilot isbns into the token through evolve then this value should never be duplicated here
// Also note this is called Isbns plural because we can support mashup courses with multiple isbns
const setIsbns = isbn => dispatch => dispatch({ type: coursewareActionType.SET_ISBNS, payload: isbn });

const setUser = ({ courseId, roleId, userId }: UserParams) => dispatch => dispatch({ type: coursewareActionType.SET_USER, payload: { courseSectionId: courseId, roleId, userId } });

const setMessages = (messages: Messages) => dispatch => dispatch({ type: coursewareActionType.SET_MESSAGES, payload: messages });

const fetchAppLinkData = (
  linkId: string
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAppLinkDataService(linkId)
    .then((response: AppLinkData) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_LINK_DATA_SUCCESS,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAppLinkData'));
};

const getExternalAppRedirectUrlAction = (
  config: AppLinkOutConfig,
  token: string = null
) => () => {
  return getExternalAppRedirectUrl(config, token);
};

const navigateToApp: NavigateToApp = (
  config: AppLinkOutConfig,
  isNewTab = false,
  token: string = null
) => (dispatch, getState) => {
  const _config = { ...config };
  dispatch({ type: coursewareActionType.REQUEST_START });

  if (isInIframe()) {
    _config.includeLinkHash = true;
  }

  return getExternalAppRedirectUrl(_config, token)
    .then(({ redirectUrl }) => {

      trackAction({
        action: AnalyticsAction.APP_LINK_OUT,
        props: {
          linkType: _config.action,
          srcApp: _config.altSrcApp || Application.SHER_EVOL,
          targetApp: _config.app
        }
      })(dispatch, getState);

      if (isNewTab) {
        window.open(redirectUrl, '_blank');
      } else {
        window.location.href = redirectUrl;
      }
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'navigateToApp'));
};

const returnAppLink = (
  { linkId, returnPostBody }: { linkId: string; returnPostBody: Partial<AppLinkReturnPostBody> }
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getLinkNavigation({ linkId, returnPostBody })
    .then(({ redirectUrl }) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      window.location.href = redirectUrl;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'returnAppLink'));
};

const fetchUserCourseOwnerRecords = (
  eolsUserHistoryRequest: {
    userId: number;
    courseSectionId: number;
    instanceId?: string;
  },
  useCache = true
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {

  if (useCache) {
    const records = cwSelectors.getCurrentUserCourseOwnerRecords(getState());
    if (records !== null) {
      return Promise.resolve(records);
    }
  }

  dispatch({ type: coursewareActionType.REQUEST_START, });
  return fetchUserHistoryByCourseByStateKeyService({
    ...eolsUserHistoryRequest,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
          payload: [],
        });
        return [];
      }
      return Promise.reject(error);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchUserCourseOwnerRecords');
    });
};

const fetchUserHistoryByCourseByStateKey = (
  eolsUserHistoryRequest: {
    userId: number;
    stateKey: CoursewareUserHistoryStateKey;
    courseSectionId: number;
    instanceId?: string;
  },
  checkRole = true
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {

  const userRole = cwSelectors.getRoleId(getState());
  if (checkRole && !CoursewareUserHistoryStateKeyRoleMap[eolsUserHistoryRequest.stateKey].includes(userRole)) {
    return Promise.resolve([]);
  }

  dispatch({ type: coursewareActionType.REQUEST_START, });
  return fetchUserHistoryByCourseByStateKeyService(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_HISTORY_SUCCESS,
        payload: { response, stateKey: eolsUserHistoryRequest.stateKey },
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_USER_HISTORY_SUCCESS,
          payload: { response: [], stateKey: eolsUserHistoryRequest.stateKey },
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'fetchUserHistoryByCourseByStateKey');
    });
};

const deleteUserHistoryWithUserId = (
  eolsUserHistoryRequest: {
    userId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return deleteUserHistoryByUserId(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: coursewareActionType.DELETE_USER_HISTORY_SUCCESS,
        payload: { response },
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.DELETE_USER_HISTORY_SUCCESS,
          payload: { response: [] },
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'deleteUserHistoryByUserId');
    });
};

const fetchSkillSubmissionRecords = (
  skillSubmissionDataRequest: {
    userId: number;
    assessmentId: string;
    courseSectionId: number;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START, });
  return fetchUserHistoryByCourseByStateKeyService({
    userId: skillSubmissionDataRequest.userId,
    stateKey: `ASSESSMENT_${skillSubmissionDataRequest.assessmentId}`,
    courseSectionId: skillSubmissionDataRequest.courseSectionId,
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD,
        payload: response,
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD,
          payload: [],
        });
        return [];
      }
      return Promise.reject(error);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchSkillSubmissionRecords');
    });
};

const setIsCourseOwner = (
  eolsUserId: number,
  courseSectionId: string,
  isCourseOwner: string,
  eolsUserHistoryDto: Partial<EolsUserHistoryResponseDto> = {},
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START, });
  return postUserHistoryService({
    ...eolsUserHistoryDto,
    stateInfo: isCourseOwner,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER,
    eolsUser: {
      id: eolsUserId
    },
    courseSectionId: parseInt(courseSectionId, 10),
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.SET_IS_COURSE_OWNER_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'setIsCourseOwner'));
};

const postUserHistory = (
  eolsUserHistoryDto: EolsUserHistoryDto,
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START, });
  return postUserHistoryService(eolsUserHistoryDto)
    .then((response) => {
      dispatch({
        type: coursewareActionType.POST_USER_HISTORY_SUCCESS,
        payload: response,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postUserHistory'));
};

const postGroupFeatureFlagAction = (
  eolsApp: string,
  featureName: string,
  group: string,
  featureValue: string
) => (dispatch): Promise<FeatureFlagDto> => {
  return postGroupFeatureFlag(eolsApp, featureName, group, featureValue)
    .then(() => {
      return {
        createdAt: null,
        eolsApp,
        featureName,
        featureValue,
        group,
        updatedAt: null
      };
    })
    .then((newFlag: FeatureFlagDto) => {
      if (newFlag.eolsApp === AppConstants.APP_ID) {
        dispatch({
          payload: newFlag,
          type: coursewareActionType.ADD_FEATURE_FLAG,
        });
      }
      return newFlag;
    })
    .catch(() => {
      return null;
    });
};

const addUpdateCourseSettingsFlagAction = (
  featureName: string,
  group: string,
  featureValue: string,
  isUpdate: boolean
) => (dispatch, getState): Promise<FeatureFlagDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  const apiHandler = isUpdate ? putGroupFeatureFlag : postGroupFeatureFlag;
  return apiHandler(
    SHER_EVOL_COURSE_SETTINGS,
    featureName,
    group,
    featureValue
  )
    .then(() => {
      return {
        createdAt: null,
        eolsApp: SHER_EVOL_COURSE_SETTINGS,
        featureName,
        featureValue,
        group,
        updatedAt: null
      };
    })
    .then((newFlag: FeatureFlagDto) => {
      dispatch({
        payload: {
          [newFlag.featureName]: newFlag
        },
        type: coursewareActionType.FETCH_COURSE_SETTINGS_FEATURE_SUCCESS,
      });
      return newFlag;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'addUpdateCourseSettingsFlagAction'));

};

const fetchABFlavor = (abTestFlag: FeatureFlagsGroupedDto) => (dispatch, getState) => {
  const abTestFlavors = cwSelectors.getABTestFlavors(getState());
  const abTestFlavor = abTestFlavors.find((flag) => {
    return flag.featureName === abTestFlag.featureName;
  });
  if (abTestFlavor) {
    return Promise.resolve(abTestFlavor);
  }
  const courseSectionId = cwSelectors.getCourseSectionId(getState());
  dispatch({ type: coursewareActionType.REQUEST_START });
  const app = `SHER_EVOL_${abTestFlag.featureName}`;
  const key = abTestFlag.featureName;
  return fetchGroupFeatureFlag(app, key, courseSectionId)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        const newFlavor = getRandomABTestFlavor(abTestFlag);
        if (!newFlavor) {
          ELSLoggingService.error(fileName, `AB test config missing for flag: ${key}`);
          return Promise.resolve({
            createdAt: null,
            eolsApp: app,
            featureName: key,
            featureValue: null,
            group: courseSectionId,
            updatedAt: null
          });
        }
        return postGroupFeatureFlagAction(app, key, courseSectionId, newFlavor)(dispatch);
      }
      return Promise.reject(error);
    })
    .then((response) => {
      dispatch({ payload: response, type: coursewareActionType.FETCH_AB_TEST_FLAVOR_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchABFlavor'));
};

const checkCanaryTest = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  canaryFeatureFlag: FEATURE_FLAG,
  canaryFeatureGroup: string,
  actualFeatureFlag: FEATURE_FLAG
) => (dispatch): Promise<FeatureFlagDto> => {

  const canaryFlag = getGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    canaryFeatureFlag,
    canaryFeatureGroup
  );

  if (!canaryFlag) {
    return Promise.resolve(null);
  }

  const [
    minCourseSectionId,
    maxNumberOfCoursesToFlip
  ] = canaryFlag.featureValue.split('_');

  if (!minCourseSectionId || !maxNumberOfCoursesToFlip) {
    return Promise.resolve(null);
  }

  if (parseInt(courseSectionId, 10) < parseInt(minCourseSectionId, 10)) {
    return Promise.resolve(null);
  }

  const foundFlag = featureFlagsGrouped.find((flag) => {
    return flag.featureName === actualFeatureFlag && flag.groups.length > 0;
  });

  if (foundFlag && (foundFlag.groups.length >= parseInt(maxNumberOfCoursesToFlip, 10) || foundFlag.groups.includes(courseSectionId))) {
    return Promise.resolve(null);
  }

  return postGroupFeatureFlagAction(AppConstants.APP_ID, actualFeatureFlag, courseSectionId, TRUE_VALUE)(dispatch);
};

const fetchCourseSettingsFlags = () => (dispatch, getState) => {
  const flags = cwSelectors.getCourseSettingsFlags(getState());
  if (flags) {
    return Promise.resolve(flags);
  }
  const courseSectionId = cwSelectors.getCourseSectionId(getState());

  if (!courseSectionId) {
    return Promise.resolve({});
  }

  const courseSettingsFlags = [
    COURSE_SETTINGS_FEATURE_FLAG.DISABLE_CHATBOT_LINK_VISIBILITY
  ];

  dispatch({ type: coursewareActionType.REQUEST_START });

  const promises = courseSettingsFlags.map((flag) => {
    return fetchGroupFeatureFlag(
      SHER_EVOL_COURSE_SETTINGS,
      flag,
      courseSectionId
    ).catch((error) => {
      if (error.response && error.response.status === 404) {
        return null;
      }
      return Promise.reject(error);
    });
  });

  return Promise.all(promises)
    .then((response) => {
      const filteredFlags: CoursewareStore['courseSettingsFlags'] = response.reduce((acc, featureFlagDto: FeatureFlagDto) => {
        if (!featureFlagDto) {
          return acc;
        }
        return {
          ...acc,
          [featureFlagDto.featureName]: featureFlagDto
        };
      }, {});
      dispatch({
        payload: filteredFlags,
        type: coursewareActionType.FETCH_COURSE_SETTINGS_FEATURE_SUCCESS,
      });
      return filteredFlags;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseSettingsFlags'));
};

const fetchAllAppFeatureFlags = () => (dispatch, getState) => {
  const featureFlagsGrouped = cwSelectors.getFeatureFlagsGrouped(getState());
  if (featureFlagsGrouped) {
    return Promise.resolve(featureFlagsGrouped);
  }
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchGroupedFeatureFlags().then((response) => {
    dispatch({
      payload: response,
      type: coursewareActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS,
    });

    const courseSectionId = cwSelectors.getCourseSectionId(getState());

    const promises = [];
    const activeABTests = getActiveABTestFlags(response, courseSectionId);

    if (activeABTests && activeABTests.length) {
      activeABTests.forEach((activeABTest) => {
        promises.push(fetchABFlavor(activeABTest)(dispatch, getState));
      });
    }

    promises.push(checkCanaryTest(
      response,
      courseSectionId,
      FEATURE_FLAG.EAQ_EDITOR_CANARY_CONFIG,
      null,
      FEATURE_FLAG.ENABLE_EAQ_ASSIGNMENT_MANAGEMENT_APP_CREATE_EDIT
    )(dispatch));

    if (promises.length) {
      return Promise.all(promises).then(() => {
        return response;
      });
    }

    return response;
  })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAllAppFeatureFlags'));
};

const fetchCatalog = (
  learningTypes: RecContentItemTypeDto[],
  useCache = false,
  reduxPage?: ReduxPageWithCatalog,
  inactiveMappingTypes: RecContentItemTypeDto[] = []
) => (dispatch, getState): Promise<CatalogWithExternalEntitiesDto> => {
  if (useCache) {
    const catalogFromStore = cwSelectors.getCatalog(getState());
    if (reduxPage) {
      const pageCatalog = makeGetCatalogInReduxPage(reduxPage)(getState());
      if (pageCatalog !== coursewareInitialState[reduxPage].catalog) {
        return Promise.resolve(pageCatalog);
      }
    } else if (catalogFromStore !== coursewareInitialState.catalog) {
      return Promise.resolve(catalogFromStore);
    }
  }
  const state = getState();
  const isbns = cwSelectors.getVantageIsbns(state) || cwSelectors.getIsbns(state);

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCatalogService(isbns, learningTypes, inactiveMappingTypes).then((data) => {
    const moduleSequenceMap = getModuleSequenceMapFromCatalog(data);
    dispatch({
      payload: {
        data,
        reduxPage,
        moduleSequenceMap
      },
      type: coursewareActionType.FETCH_CATALOG_SUCCESS
    });
    return data;
  }).catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCatalog'));
};

const fetchPrimaryTaxonomies = (
  primaryTaxonomies: PrimaryTaxonomy[],
  useCache = false,
  reduxPage?: ReduxPageWithPrimaryTaxonomies,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => (dispatch, getState): Promise<PrimaryTaxonomy[]> => {
  const primaryTaxonomiesFromStore = cwSelectors.getPrimaryTaxonomies(getState());
  const isDataExisted = primaryTaxonomiesFromStore !== coursewareInitialState.primaryTaxonomies;
  if (useCache && isDataExisted) {
    if (reduxPage) {
      const primaryTaxonomiesFromPageStore = makeGetPrimaryTaxonomiesInReduxPage(reduxPage)(getState());
      if (primaryTaxonomiesFromPageStore !== coursewareInitialState[reduxPage].primaryTaxonomies) {
        return Promise.resolve(primaryTaxonomiesFromPageStore);
      }
    } else if (primaryTaxonomiesFromStore !== coursewareInitialState.primaryTaxonomies) {
      return Promise.resolve(primaryTaxonomiesFromStore);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START });

  return Promise.all(
    primaryTaxonomies.map((primaryTaxonomy) => {
      return fetchPrimaryTaxonomy(primaryTaxonomy.isbn)
        .then((response: PrimaryTaxonomyDto) => {
          if (response.data.length !== 1) {
            ELSLoggingService.error(fileName, 'Primary taxonomy DTO should ALWAYS have 1 primary taxonomy');
          }
          return {
            ...primaryTaxonomy,
            taxonomy: response
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            return {
              ...primaryTaxonomy,
              taxonomy: null
            };
          }
          return Promise.reject(error);
        });
    })
  )
    .then((responses) => {
      return responses.reduce((acc, cur) => {
        if (!cur.taxonomy) {
          return acc;
        }
        return [...acc, cur];
      }, []);
    })
    .then((data) => {
      dispatch({ payload: { data, reduxPage }, type: coursewareActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchPrimaryTaxonomies'));
};

const fetchTaxonomy = (taxonomyId: string) => (dispatch, getState): Promise<RecTaxonomyNodeDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchTaxonomyService(taxonomyId)
    .then((data) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchTaxonomy'));
};

const openVitalSource = (
  isbn: string,
  page: number
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchVitalSourceService({
    isbn,
    page,
  })
    .then(vitalSource => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return window.open(vitalSource.signInUrl, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'openVitalSource'));
};

const fetchSimulationAssignment = (assignmentId: string, courseSectionId: string, userRole: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  if (!isInstructor(userRole)) {
    // This API call will add the user to assignments so need to avoid calling it as a student
    const assignments = cwSelectors.getAssignments(getState());
    const simulationAssignment = assignments.find(assignmentItem => assignmentItem.id === Number(assignmentId));
    dispatch({
      payload: simulationAssignment,
      type: coursewareActionType.FETCH_ASSIGNMENT_SUCCESS,
    });
    return Promise.resolve(simulationAssignment);
  }

  return fetchAssignmentsService(courseSectionId)
    .then(assignments => {
      const simulationAssignment = assignments.find(assignmentItem => assignmentItem.id === Number(assignmentId));
      dispatch({
        payload: simulationAssignment,
        type: coursewareActionType.FETCH_ASSIGNMENT_SUCCESS,
      });
      return simulationAssignment;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSimulationAssignment'));
};

const fetchGroupActivity = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchResourceInfo(vtwId)
    .then(response => {
      dispatch({
        payload: response as GroupActivityDto,
        type: coursewareActionType.FETCH_GROUP_ACTIVITY_SUCCESS
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchGroupActivity'));
};

const getInProgressAssessmentId = (assessmentId: number, assignmentId: number, userId: string) => (dispatch, getState): Promise<number> => {
  return assessmentId
    ? Promise.resolve(assessmentId)
    : fetchRecentAssessmentOfAssignment(userId, assignmentId)(dispatch, getState)
      .then(recentAssessment => {
        return recentAssessment.status === AssessmentStatusDto.IN_PROGRESS ? recentAssessment.id : null;
      });
};

const trackAssessmentSubmissionAdobeAction = (assignment: AssignmentDto) => (dispatch, getState) => {
  if (assignment && assignment.dueDate) {
    trackAdobeAction({
      action: AdobeAnalyticsAction.ASSIGNMENT_COMPLETED,
      pageData: {
        education: {
          assignmentType: assignment.assignmentType,
          assignmentId: assignment.id.toString(),
          assignmentName: assignment.title,
          beforeAfterDueDate: getBeforeAfterDueDateDue(assignment.dueDate)
        }
      }
    })(dispatch, getState);
  } else {
    trackAdobeAction({
      action: AdobeAnalyticsAction.ASSESSMENT_COMPLETED,
      pageData: {
        education: {
          assignmentType: assignment.assignmentType,
        }
      }
    })(dispatch, getState);
  }
};

const createOsmosisSubmission = (assessmentId: number, assignment: AssignmentDto, userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  const assessmentSubmission: Partial<AssessmentSubmissionDto> = {
    userId: Number(userId),
    score: 1,
    responseTime: moment.utc().format(),
    offsetSeconds: 1,
    totalAnswered: 1,
    totalCorrect: 1,
  };
  return submitAssessmentScore(assessmentId, assessmentSubmission)
    .then((assessmentSubmissionDto) => {
      trackAssessmentSubmissionAdobeAction(assignment)(dispatch, getState);
      fetchRecentAssessmentOfAssignment(userId, assignment.id)(dispatch, getState);
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return assessmentSubmissionDto;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'createOsmosisSubmission'));
};

const createSimulationSubmission = (assessmentId: number, assignment: AssignmentDto, userId: string) => (dispatch, getState) => {

  const timeStartMap = cwSelectors.getAssessmentStartTimeMap(getState());

  dispatch({ type: coursewareActionType.REQUEST_START });
  const assessmentSubmission: Partial<AssessmentSubmissionDto> = {
    userId: Number(userId),
    score: 1,
    responseTime: moment.utc().format(),
    offsetSeconds: 1,
    totalAnswered: 1,
    totalCorrect: 1,
  };

  return getInProgressAssessmentId(assessmentId, assignment.id, userId)(dispatch, getState)
    .then(inProgressAssessmentId => {
      const startTime = timeStartMap[inProgressAssessmentId.toString()];
      if (startTime) {
        const timeSpentSeconds = moment.duration(moment().diff(moment(startTime))).asSeconds();
        assessmentSubmission.timeSpent = Math.floor(timeSpentSeconds);
      }
      return submitAssessmentScore(inProgressAssessmentId, assessmentSubmission)
        .then(() => {
          trackAssessmentSubmissionAdobeAction(assignment)(dispatch, getState);
          fetchRecentAssessmentOfAssignment(userId, assignment.id)(dispatch, getState);
          dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
        })
        .catch((e) => handleRequestError(dispatch, getState)(e, 'createSimulationSubmission'));
    });
};

const fetchAssessmentSubmissions = (
  assignmentId: string,
  isTrackRequestCount = true
) => (dispatch, getState): Promise<AssessmentSubmissionDto[]> => {
  if (isTrackRequestCount) {
    dispatch({ type: coursewareActionType.REQUEST_START });
  }
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((assessmentSubmissions) => {
      const payload = { assignmentId, assessmentSubmissions, isTrackRequestCount };
      dispatch({ payload, type: coursewareActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS });
      return assessmentSubmissions;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissions', isTrackRequestCount));
};

const fetchAllAssessmentSubmissionsAction = (
  assignmentIds: string[],
  isTrackRequestCount = true
) => (dispatch, getState): Promise<Record<string, AssessmentSubmissionDto[]>> => {
  if (isTrackRequestCount) {
    dispatch({ type: coursewareActionType.REQUEST_START });
  }
  return Promise.all(
    assignmentIds.map((assignmentId) => {
      return fetchAssessmentSubmissionsService(assignmentId)
        .then((assessmentSubmissions) => {
          return {
            assignmentId,
            assessmentSubmissions
          };
        });
    })
  )
    .then((assessmentSubmissionsResponses) => {
      const payload = {
        assessmentSubmissionsMap: getAssessmentSubmissionsMap(assessmentSubmissionsResponses),
        isTrackRequestCount
      };
      dispatch({ payload, type: coursewareActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS });
      return payload.assessmentSubmissionsMap;
    })
    .catch((e) => {
      return handleRequestError(dispatch, getState)(e, 'fetchAllAssessmentSubmissionsAction', isTrackRequestCount);
    });
};
const fetchEvolveInstructorResourceDataAction = (
  syllabusItems: SyllabusItemDictionary,
  isTrackRequestCount = true
) => (dispatch, getState): Promise<Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']>> => {
  if (isTrackRequestCount) {
    dispatch({ type: coursewareActionType.REQUEST_START });
  }
  const promiseArr: Promise<{ key: string; _parsedData: CatalogEvolveResourceExternalEntityDtoParsed['_parsedData'] }>[] = [];
  Object.keys(syllabusItems).forEach((key) => {
    if (syllabusItems[key].assignment && syllabusItems[key].type === ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE) {
      promiseArr.push(fetchEvolveResource(syllabusItems[key].assignment.contentId)
        .then((response) => {
          const _parsedData = JSON.parse(response.data) as CatalogEvolveResourceExternalEntityDtoParsed['_parsedData'];
          return {
            key: syllabusItems[key].assignment.contentId,
            _parsedData
          };
        }));
    }
  });
  return Promise.all(promiseArr)
    .then((parsedDataResponses) => {
      const payload = {
        parsedDataMap: getParsedDataMap(parsedDataResponses),
        isTrackRequestCount
      };
      dispatch({ payload, type: coursewareActionType.FETCH_ALL_EVOLVE_INSTRUCTOR_RESOURCE_DATA_SUCCESS });
      return payload.parsedDataMap;
    })
    .catch((e) => {
      return handleRequestError(dispatch, getState)(e, 'fetchEvolveInstructorResourceDataAction', isTrackRequestCount);
    });
};

const fetchAssignments = (
  courseSectionId: string,
  userRole: string,
  useCache = true
) => (dispatch, getState): Promise<AssignmentDto[]> => {
  if (useCache) {
    const cache = cwSelectors.getAssignments(getState());
    if (cache && cache.length) {
      return Promise.resolve(cache);
    }
  }

  if (!isInstructor(userRole)) {
    // This API call will add the user to assignments so need to avoid calling it as a student
    return Promise.resolve(cwSelectors.getAssignments(getState()));
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignmentsService(courseSectionId)
    .then((assignments) => {
      const payload = { assignments };
      dispatch({ payload, type: coursewareActionType.FETCH_ASSIGNMENTS_SUCCESS });
      return Promise.resolve(assignments);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignments'));
};

const fetchSyllabusItems = (
  courseSectionId: string,
  useCache = true
) => (dispatch, getState): Promise<CourseManagementSyllabusItemsDto> => {
  const isDataExisted = cwSelectors.getAllSyllabusItems(getState()) !== coursewareInitialState.syllabusItems;
  if (useCache && isDataExisted) {
    return Promise.resolve({
      externalEntities: cwSelectors.getExternalEntities(getState()),
      syllabusItems: cwSelectors.getAllSyllabusItems(getState()),
    });
  }

  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchSyllabusItemsV3({
    courseSectionIds: [courseSectionId]
  })
    .then((data: CourseManagementSyllabusItemsDto) => {
      const payload = data;
      dispatch({ payload, type: coursewareActionType.FETCH_SYLLABUS_ITEMS_SUCCESS });
      return Promise.resolve(payload);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSyllabusItems'));
};

const postSyllabusItem = (syllabusItem) => (dispatch, getState): Promise<SyllabusItemDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postSyllabusItemService(syllabusItem)
    .then((data: SyllabusItemDto) => {
      dispatch({ payload: data, type: coursewareActionType.POST_SYLLABUS_ITEMS_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postSyllabusItem'));
};

const putSyllabusItems = (newSyllabusItems: SyllabusItemDto[]) => (dispatch, getState): Promise<SyllabusItemDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusItemsService(newSyllabusItems)
    .then((data: SyllabusItemDto[]) => {
      dispatch({ payload: data, type: coursewareActionType.PUT_SYLLABUS_ITEMS_BATCH_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putSyllabusItems'));
};

const postSyllabusItems = (newSyllabusItems: SyllabusItemDto[]) => (dispatch, getState): Promise<SyllabusItemDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postSyllabusItemsService(newSyllabusItems)
    .then((data: SyllabusItemDto[]) => {
      dispatch({ payload: data, type: coursewareActionType.POST_SYLLABUS_ITEMS_BATCH_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postSyllabusItems'));
};

const putSyllabusItemAction = (newSyllabusItem: SyllabusItemDto) => (dispatch, getState): Promise<SyllabusItemDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusItem(newSyllabusItem)
    .then((data: SyllabusItemDto) => {
      dispatch({ payload: data, type: coursewareActionType.PUT_SYLLABUS_ITEM_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putSyllabusItem'));
};

const postSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto) => (dispatch, getState): Promise<SyllabusAssignmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postSyllabusAssignmentService(syllabusAssignmentDto)
    .then((data: SyllabusAssignmentDto) => {
      // Only considering assignment with due dates as assignments for Adobe
      if (data.assignment && data.assignment.dueDate) {
        trackAdobeAction({
          action: AdobeAnalyticsAction.ASSIGNMENT_CREATED,
          pageData: {
            education: {
              assignmentType: data.assignment.assignmentType,
              assignmentId: data.assignment.id.toString(),
              assignmentName: data.assignment.title,
              beforeAfterDueDate: getBeforeAfterDueDateDue(data.assignment.dueDate)
            }
          }
        })(dispatch, getState);
      }
      dispatch({ payload: data, type: coursewareActionType.POST_SYLLABUS_ASSIGNMENT_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postSyllabusAssignment'));
};

const putSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto) => (dispatch, getState): Promise<SyllabusAssignmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusAssignmentService(syllabusAssignmentDto)
    .then((data: SyllabusAssignmentDto) => {
      // Only considering assignment with due dates as assignments for Adobe
      if (data.assignment && data.assignment.dueDate) {
        trackAdobeAction({
          action: AdobeAnalyticsAction.ASSIGNMENT_CREATED,
          pageData: {
            education: {
              assignmentType: data.assignment.assignmentType,
              assignmentId: data.assignment.id.toString(),
              assignmentName: data.assignment.title,
              beforeAfterDueDate: getBeforeAfterDueDateDue(data.assignment.dueDate)
            }
          }
        })(dispatch, getState);
      }
      dispatch({ payload: data, type: coursewareActionType.PUT_SYLLABUS_ASSIGNMENT_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putSyllabusAssignment'));
};

const deleteSyllabusItems = (syllabusItemsToDelete: SyllabusItemDto[]) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusItemsService(syllabusItemsToDelete.map((item) => {
    return {
      ...item,
      isDeleted: true
    };
  }))
    .then((data: SyllabusItemDto[]) => {
      dispatch({ payload: data, type: coursewareActionType.DELETE_SYLLABUS_ITEMS_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'deleteSyllabusItems'));
};

const fetchEolsUser = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserService(userId)
    .then((data) => {
      dispatch({ payload: data, type: coursewareActionType.FETCH_EOLS_USER_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEolsUser'));
};

const fetchEvolveUsers = (userId: string, useCache = false) => (dispatch, getState) => {
  if (useCache) {
    const evolveUser = cwSelectors.getEvolveUser(getState());
    if (evolveUser) {
      return Promise.resolve(evolveUser);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      dispatch({ payload: crosswalkUser, type: coursewareActionType.FETCH_EVOLVE_USER_SUCCESS });
      return crosswalkUser;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveUsers'));
};

const fetchCrosswalkUsers = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      return Promise.all(crosswalkUser.eolsUserIdsByUserNameAndType.map(eolsUserIdByUserNameAndType => {
        return fetchUserService(eolsUserIdByUserNameAndType.toString());
      }));
    })
    .then(users => dispatch({ payload: users, type: coursewareActionType.FETCH_CROSSWALK_USERS_SUCCESS }))
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCrosswalkUsers'));
};

const fetchSkillStaticData = (skillVersionVtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSkillStaticDataService(skillVersionVtwId)
    .then((staticDataRes) => {
      dispatch({ payload: staticDataRes, type: coursewareActionType.FETCH_SKILL_STATIC_DATA });
      return staticDataRes;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSkillStaticData'))
    .catch(() => []);
};

const postCourseCopy = (courseSectionId: string, startDate, timezone: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postCourseCopyService(courseSectionId, startDate, timezone)
    .then((data) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postCourseCopy'));
};

const putAssignments = (assignments: AssignmentDto[]) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  const requests = assignments.map(assignment => putAssignmentService(assignment));
  return Promise.all(requests)
    .then(() => {
      const assignmentsDictionary = keyBy(assignments, 'id');
      dispatch({ payload: assignmentsDictionary, type: coursewareActionType.PUT_ASSIGNMENTS_SUCCESS });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putAssignments'));
};

const postCreateAssessmentFromAssignment = (newAssessment: Partial<AssessmentDto>) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postCreateAssessmentFromAssignmentService(newAssessment)
    .then((assessment) => {
      dispatch({ payload: assessment, type: coursewareActionType.POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS });
      return assessment;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postCreateAssessmentFromAssignment'));
};

const setIsBatchEditMode = (isBatchEditModeEnabled: boolean) => (dispatch) => {
  dispatch({ payload: isBatchEditModeEnabled, type: coursewareActionType.SET_IS_BATCH_EDIT_MODE });
};

const setDragDropMode = (isDragDropMode: boolean) => (dispatch) => {
  dispatch({ payload: isDragDropMode, type: coursewareActionType.SET_IS_DRAG_DROP_MODE });
};

const setCheckedSyllabusItemIds = (checkedSyllabusItemIds: string[]) => (dispatch) => {
  dispatch({ payload: checkedSyllabusItemIds, type: coursewareActionType.SET_CHECKED_SYLLABUS_ITEMS });
};

const setSyllabusFolderInfo = (syllabusFolderInfo: SyllabusFolderLocationInfo) => (dispatch) => {
  dispatch({ payload: syllabusFolderInfo, type: coursewareActionType.SET_SYLLABUS_FOLDER_INFO });
};

const updateCourseBuilderState = (newState: Partial<CourseBuilderStore>) => (dispatch) => {
  dispatch({ payload: newState, type: coursewareActionType.UPDATE_COURSE_BUILDER_STATE });
};

const productFilterChange = (product: string, reduxPage: ReduxPageWithFilter) => (dispatch) => {
  dispatch({ payload: { product, reduxPage }, type: coursewareActionType.HANDLE_PRODUCT_FILTER_CHANGE });
};

const isTrackEbookFilterChange = (props: EbookFilterChangeProps): boolean => {
  if (!props.checkboxValue) {
    return false;
  }
  if (props.field === EbookFilterField.CHAPTER_CHECKBOX && !props.chapterId) {
    return false;
  }
  return true;
};

const ebookFilterChange = (props: EbookFilterChangeProps, reduxPage: ReduxPageWithFilter) => (dispatch, getState) => {
  if (isTrackEbookFilterChange(props)) {
    trackAction({
      action: AnalyticsAction.RESOURCE_FILTER_ACTIVATED,
      props: {
        type: AnalyticsFilterType.EBOOK_FILTER,
        option: props.chapterId || props.bookId, // TODO: Deprecated - remove once New Relic dashboards are updated
        optionValue: props.chapterId || props.bookId,
        optionDisplay: props.displayName,
        chapterId: props.chapterId,
        bookId: props.bookId,
      }
    })(dispatch, getState);
  }
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.HANDLE_EBOOK_FILTER_CHANGE });
};

const clinicalSkillsFilterChange = (props: ClinicalSkillsFilterChangeProps, reduxPage: ReduxPageWithFilter) => (dispatch, getState) => {
  if (isTrackEbookFilterChange(props as unknown as EbookFilterChangeProps)) {
    trackAction({
      action: AnalyticsAction.RESOURCE_FILTER_ACTIVATED,
      props: {
        type: AnalyticsFilterType.CLINICAL_SKILLS_FILTER,
        option: props.chapterId || props.bookId, // TODO: Deprecated - remove once New Relic dashboards are updated
        optionValue: props.chapterId || props.bookId,
        optionDisplay: props.displayName,
        chapterId: props.chapterId,
        bookId: props.bookId,
      }
    })(dispatch, getState);
  }
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.HANDLE_CLINICAL_SKILLS_FILTER_CHANGE });
};

const resourceStatusFilterChange = (props: Array<string>, reduxPage: ReduxPageWithFilter) => (dispatch) => {
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.HANDLE_RESOURCE_STATUS_FILTER_CHANGE });
};

const resourceTypeFilterChange = (props: Array<ActiveSyllabusItemTypeDto>, reduxPage: ReduxPageWithFilter) => (dispatch) => {
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.HANDLE_RESOURCE_TYPE_FILTER_CHANGE });
};

const resourceGradingFilterChange = (props: Array<ActiveSyllabusItemTypeDto>, reduxPage: ReduxPageWithFilter) => (dispatch) => {
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.HANDLE_RESOURCE_GRADING_FILTER_CHANGE });
};

const setAssessmentStart = (
  simulationAssignment: AssignmentDto,
  assessmentId: number,
  userId: string,
  startTime: Date,
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getInProgressAssessmentId(assessmentId, simulationAssignment.id, userId)(dispatch, getState)
    .then(inProgressAssessmentId => {
      if (inProgressAssessmentId) {
        const payload = {
          startTime,
          assessmentId: inProgressAssessmentId,
          assignment: simulationAssignment,
        };
        dispatch({
          payload,
          type: coursewareActionType.SET_ASSESSMENT_START_TIME_MAP
        });
        return payload;
      }
      const newAssessment: Partial<AssessmentDto> = {
        assignmentId: simulationAssignment.id,
        userId: Number(userId),
      };
      return postCreateAssessmentFromAssignment(newAssessment)(dispatch, getState)
        .then((assessment) => {
          const payload = {
            startTime,
            assignment: simulationAssignment,
            assessmentId: assessment.id
          };
          dispatch({
            payload,
            type: coursewareActionType.SET_ASSESSMENT_START_TIME_MAP
          });
          return payload;
        })
        .catch((e) => handleRequestError(dispatch, getState)(e, 'setAssessmentStart'));
    });
};

const setRegisteredToken = (props: string) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.SET_REGISTERED_TOKEN });
};

const setEbookFilterState = (props: EbookFilterState) => ({
  payload: props, type: coursewareActionType.SET_EBOOK_FILTER
});

const setAppLinkCookies = (props: {
  token: string;
  linkId: string;
}) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.SET_APP_LINK_COOKIES });
};

const updateCollapsedFolderIds = (props: string[], userId: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({ payload: props, type: coursewareActionType.UPDATE_COLLAPSED_FOLDER_IDS });

  const eolsUserHistoryDTO = {
    eolsUser: {
      id: parseInt(userId, 10)
    },
    stateKey: CoursewareUserHistoryStateKey.COLLAPSED_FOLDERS,
    courseSectionId: parseInt(courseSectionId, 10),
    stateInfo: JSON.stringify(props)
  };

  return fetchUserHistoryByCourseByStateKeyService({
    userId: eolsUserHistoryDTO.eolsUser.id,
    stateKey: eolsUserHistoryDTO.stateKey,
    courseSectionId: eolsUserHistoryDTO.courseSectionId
  })
    .then((response: EolsUserHistoryResponseDto[]) => {
      return response.sort((a, b) => {
        return a.id - b.id;
      }).find((userHistory) => {
        return userHistory.stateKey === eolsUserHistoryDTO.stateKey;
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return {} as EolsUserHistoryResponseDto;
      }
      return Promise.reject(error);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserHistoryByCourseByStateKeyService', false))
    .catch(() => {
      return {} as EolsUserHistoryResponseDto;
    })
    .then((userHistory) => {
      return postUserHistoryService({
        ...userHistory,
        ...eolsUserHistoryDTO
      });
    });
};

const setStoreProps = (props: Partial<CoursewareStore>) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.SET_STORE_PROPS });
};

const fetchCourseCopyPreview = (courseSectionId: string, timezone: string, startDate: string) => (dispatch, getState): Promise<CourseCopyPreviewDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCourseCopyPreviewService(courseSectionId, timezone, startDate)
    .then((courseCopyPreviewDto) => {
      const payload: Partial<CourseBuilderStore> = {
        courseCopyPreviewDto
      };
      dispatch({ payload, type: coursewareActionType.FETCH_COURSE_COPY_PREVIEW_SUCCESS });
      return Promise.resolve(courseCopyPreviewDto);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseCopyPreview'));
};

const setHesiFocusChapterFilter = (props: boolean, reduxPage: ReduxPageWithFilter) => (dispatch, getState) => {
  if (props) {
    trackAction({
      action: AnalyticsAction.RESOURCE_FILTER_ACTIVATED,
      props: {
        type: AnalyticsFilterType.HESI_FOCUS_CHAPTER,
        option: AnalyticsFilterType.HESI_FOCUS_CHAPTER, // TODO: Deprecated - remove once New Relic dashboards are updated
        optionValue: AnalyticsFilterType.HESI_FOCUS_CHAPTER,
        optionDisplay: en.HESI_FOCUS_CHAPTERS,
      }
    })(dispatch, getState);
  }
  dispatch({ payload: { props, reduxPage }, type: coursewareActionType.SET_HESI_FOCUS_CHAPTER_FILTER });
};

const setBatchEditSelectedSyllabusItems = (props: SyllabusItemDto[]) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS });
};

const setBatchEditUpdatedSyllabusItems = (props: SyllabusItemDto[]) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS });
};

const setEnableDeepLink = (enableDeepLink: boolean) => (dispatch) => {
  dispatch({ payload: enableDeepLink, type: coursewareActionType.SET_ENABLE_DEEP_LINK });
};

const clearAllFilters = (reduxPage: ReduxPageWithFilter) => (dispatch) => {
  dispatch({ payload: reduxPage, type: coursewareActionType.CLEAR_ALL_FILTERS });
};

const fetchUserEngagementReport = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchUserEngagementReportService(courseSectionId)
    .then((response: EolsUserEngagementDto[]) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS,
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserEngagementReport'));
};

const setHasRunAuthessHealthCheck = (hasRunAuthessHealthCheck: boolean) => (dispatch) => {
  dispatch({ payload: hasRunAuthessHealthCheck, type: coursewareActionType.SET_HAS_RUN_AUTHESS_HEALTH_CHECK });
};

const fetchAuthessHealthCheck = (courseSectionId: string) => (dispatch, getState) => {
  return fetchAuthessHealthCheckService(courseSectionId)
    .then((response) => {
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAuthessHealthCheck', false));
};

const putMigrateSyllabusItemsAction = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putMigrateSyllabusItems(courseSectionId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putMigrateSyllabusItemsAction'));
};

const fetchEvolveResourceAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchEvolveResource(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveResourceAction'))
    .catch(() => null);
};

const fetchHashLinkAction = (hashLink: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchHashLink(hashLink)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchHashLinkAction'));
};

const fetchResourceInfoAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchResourceInfo(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchResourceInfoAction'))
    .catch(() => {
      return null;
    });
};

const fetchDeepLinkDataAction = (deepLinkGuid: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchDeepLinkData(deepLinkGuid, courseSectionId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchDeepLinkDataAction'));
};

const postDeepLinkAction = (data: DeepLinkPostDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postDeepLink(data)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postDeepLinkAction'));
};

const fetchAssignmentAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignment(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentAction'));
};

const fetchRelatedHesiCoursesAction = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchRelatedHesiCourses(userId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRelatedHesiCoursesAction'));
};

const getShadowHealthRelatedCoursesAction = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getShadowHealthRelatedCourses(userId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'getShadowHealthRelatedCoursesAction'));
};

const fetchNewTokenForCourseSwitcherAction = (body: AccessTokenRequestDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchNewTokenForCourseSwitcher(body)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchNewTokenForCourseSwitcherAction'));
};

const fetchUserCourseSectionsAction = (userId: string, active = true, entitled?: boolean) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserCourseSections(userId, active, entitled)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserCourseSectionsAction'));
};

const goToShadowAction = (course: ShadowHealthCourseDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return goToShadow(course)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'goToShadowAction'));
};

const fetchEvolveProductsAction = (courseSectionId: string, isbns: string[]) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchEvolveProducts(courseSectionId, isbns)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveProductsAction'));
};

const fetchSherpathSchedulesAction = (courseSectionId: string, tokenDto: NewTokenDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSherpathSchedules(courseSectionId, tokenDto)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSherpathSchedulesAction'));
};

const fetchLearningObjectLessonAndModuleAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchLearningObjectLessonAndModule(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchLearningObjectLessonAndModuleAction'))
    .catch(() => {
      return null;
    });
};

const postLearningContextAction = (data: LearningContextDto) => (dispatch, getState) => {
  // This request does not work for Courseware uses because it contains an entitlements check against the Sherpath course section
  // Leaving it here in case we ever extend the entitlements check but for now DO NOT USE
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postLearningContext(data)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postLearningContextAction'));
};

const fetchAnalyticsContextAction = (analyticsRequestDto: LearningAnalyticsRequestDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAnalyticsContext(analyticsRequestDto)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAnalyticsContextAction'))
    .catch(() => null);
};

const fetchLessonTopicsAction = (lessonId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchLessonTopics(lessonId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchLessonTopicsAction'));
};

const fetchAssessmentSubmissionsAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissionsAction'));
};

const fetchSyllabusItemAction = (id: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSyllabusItem(id)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSyllabusItemAction'));
};

const fetchAssignmentResultsAction = (assignmentId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignmentResults(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentResultsAction'));
};

const postProvisionStudentAssignmentDataAction = (sessionEvent: SessionEvent) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postProvisionStudentAssignmentData(sessionEvent)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postProvisionStudentAssignmentDataAction'));
};

const fetchSkillPerformanceAction = (assignmentId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSkillPerformance(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSkillPerformanceAction'));
};

const fetchOsmosisTranscriptAction = (learnName: string, auth: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchOsmosisTranscript(learnName, auth)
    .then((response) => {
      const scriptLink = get(response, 'data.learnData.scriptLink');
      if (!scriptLink) {
        return Promise.reject(new Error('Missing scriptLink'));
      }
      return axios.get(scriptLink).then((_response: AxiosResponse<string>) => {
        dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
        return {
          transcriptData: response.data,
          transcript: _response.data
        };
      });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchOsmosisTranscriptAction'));
};

const fetchOsmosisTokenAction = () => (dispatch, getState) => {

  const token = cwSelectors.getOsmosisTokenDto(getState());
  if (!shouldGetNewOsmosisToken(token)) {
    return Promise.resolve(token);
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchOsmosisToken()
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_OSMOSIS_TOKEN_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchOsmosisTokenAction'));
};

const fetchMigratedEntitlementsAction = (courseSectionId: string) => (dispatch, getState) => {
  const migratedEntitlements = cwSelectors.getMigratedEntitlements(getState());
  if (migratedEntitlements) {
    return Promise.resolve(migratedEntitlements);
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchMigratedEntitlements(courseSectionId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_MIGRATED_ENTITLEMENTS_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchMigratedEntitlementsAction'));
};

const downloadSherpathClassicGradeExportAction = () => (dispatch, getState) => {
  const timeZoneOffset = new Date().getTimezoneOffset().toString();
  const courseName = cwSelectors.getCourseName(getState());
  const courseSectionId = cwSelectors.getCourseSectionId(getState());
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSherpathClassicGradeExport(courseSectionId, timeZoneOffset)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation;charset=UTF-8'
      });

      const objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);

      const link = document.createElement('a');

      link.setAttribute(
        'href',
        objectUrl,
      );

      link.setAttribute(
        'download',
        `Grades_${courseName}_${moment(new Date()).format('MM-DD-YYYY')}.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSherpathClassicGradeExport'));
};

const openSupportTicket = (supportTicketDto: SupportTicketPostBodyDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postGetSupportTicketUrl(supportTicketDto)
    .then(rightNowLink => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return window.open(rightNowLink, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'openSupportTicket'));
};

const goToEvolve = () => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getEvolveToken()
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      const { myEvolveUrl } = ServerConstants[ELSCommonConfig.appProfile];
      // TODO : make this open in a new tab
      const isAutomationTesting = localStorage.getItem('isAutomationTesting');
      const target = isAutomationTesting ? '_self' : '_blank';
      return window.open(`${myEvolveUrl}?token=${response.token.token}`, target);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'goToEvolve'));
};

const setProgressIndicatorValues = (progressIndicatorValues: { completed: number; total: number }) => (dispatch) => {
  dispatch({ payload: progressIndicatorValues, type: coursewareActionType.SET_PROGRESS_INDICATOR_VALUES });
};

const incrementProgressIndicator = (progressIncrement: number) => (dispatch) => {
  dispatch({ payload: progressIncrement, type: coursewareActionType.INCREMENT_PROGRESS_INDICATOR });
};

const goToEvolveAccount = () => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getEvolveToken()
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      const { myEvolveAccountUrl } = ServerConstants[ELSCommonConfig.appProfile];
      const isAutomationTesting = localStorage.getItem('isAutomationTesting');
      const target = isAutomationTesting ? '_self' : '_blank';
      return window.open(`${myEvolveAccountUrl}?token=${response.token.token}`, target);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'goToEvolveAccount'));
};

const setIsAppLinkAssignmentCreateFlow = (isAppLinkAssignmentCreateFlow: boolean) => (dispatch) => {
  dispatch({ payload: isAppLinkAssignmentCreateFlow, type: coursewareActionType.SET_IS_APP_LINK_ASSIGNMENT_CREATE_FLOW });
};

const setAppLinkAssignmentStudents = (appLinkAssignmentStudents: number[]) => (dispatch) => {
  dispatch({ payload: appLinkAssignmentStudents, type: coursewareActionType.SET_APP_LINK_ASSIGNMENT_STUDENTS });
};

const updateCollapsedFilterTitles = (props: string[]) => (dispatch) => {
  dispatch({ payload: props, type: coursewareActionType.UPDATE_COLLAPSED_FILTER_TITLES });
};

export const cwActions = {
  createSimulationSubmission,
  deleteSyllabusItems,
  deleteUserHistoryByUserId: deleteUserHistoryWithUserId,
  ebookFilterChange,
  clinicalSkillsFilterChange,
  fetchAppLinkData,
  fetchAssessmentSubmissions,
  fetchRecentAssessmentOfAssignment,
  fetchAssignments,
  fetchCatalog,
  fetchCourseSection,
  fetchCrosswalkUsers,
  fetchEolsUser,
  fetchEvolveUsers,
  fetchPrimaryTaxonomies,
  fetchSimulationAssignment,
  fetchCourseCopyPreview,
  fetchSyllabusItems,
  fetchTaxonomy,
  fetchUserCourseOwnerRecords,
  fetchUsers,
  fetchUserHistoryByCourseByStateKey,
  navigateToApp,
  openVitalSource,
  postCourseCopy,
  postSyllabusAssignment,
  postSyllabusItem,
  postUserHistory,
  productFilterChange,
  putSyllabusAssignment,
  putSyllabusItems,
  postSyllabusItems,
  putAssignments,
  resetState,
  resetStateOnLaunch,
  resourceStatusFilterChange,
  resourceTypeFilterChange,
  resourceGradingFilterChange,
  restoreState,
  setAssessmentStart,
  setCheckedSyllabusItemIds,
  setSyllabusFolderInfo,
  setDragDropMode,
  setHesiFocusChapterFilter,
  setIsBatchEditMode,
  setIsbns,
  setIsCourseOwner,
  setMessages,
  setStoreProps,
  setUser,
  setRegisteredToken,
  updateCourseBuilderState,
  updateCourseSection,
  setEbookFilterState,
  fetchAllAppFeatureFlags,
  fetchSkillStaticData,
  fetchSkillSubmissionRecords,
  setAppLinkCookies,
  returnAppLink,
  updateCollapsedFolderIds,
  setBatchEditSelectedSyllabusItems,
  setBatchEditUpdatedSyllabusItems,
  trackAction,
  fetchUserEngagementReport,
  clearAllFilters,
  fetchGroupActivity,
  trackAdobeAction,
  setEnableDeepLink,
  setHasRunAuthessHealthCheck,
  fetchAuthessHealthCheck,
  putMigrateSyllabusItemsAction,
  fetchEvolveResourceAction,
  fetchHashLinkAction,
  fetchResourceInfoAction,
  fetchDeepLinkDataAction,
  postDeepLinkAction,
  fetchAssignmentAction,
  fetchRelatedHesiCoursesAction,
  getShadowHealthRelatedCoursesAction,
  fetchNewTokenForCourseSwitcherAction,
  fetchUserCourseSectionsAction,
  goToShadowAction,
  fetchEvolveProductsAction,
  fetchSherpathSchedulesAction,
  fetchLearningObjectLessonAndModuleAction,
  postLearningContextAction,
  fetchAnalyticsContextAction,
  fetchLessonTopicsAction,
  fetchAssessmentSubmissionsAction,
  fetchSyllabusItemAction,
  fetchAssignmentResultsAction,
  getExternalAppRedirectUrlAction,
  postProvisionStudentAssignmentDataAction,
  fetchSkillPerformanceAction,
  createOsmosisSubmission,
  postCreateAssessmentFromAssignment,
  fetchOsmosisTokenAction,
  fetchAssessmentsByAssignmentIdAndUserIdAction,
  fetchAllAssessmentSubmissionsAction,
  fetchEvolveInstructorResourceDataAction,
  fetchOsmosisTranscriptAction,
  fetchMigratedEntitlementsAction,
  downloadSherpathClassicGradeExportAction,
  openSupportTicket,
  goToEvolve,
  setProgressIndicatorValues,
  incrementProgressIndicator,
  goToEvolveAccount,
  putSyllabusItemAction,
  setIsAppLinkAssignmentCreateFlow,
  setAppLinkAssignmentStudents,
  checkCanaryTest,
  updateCollapsedFilterTitles,
  postGroupFeatureFlagAction,
  fetchCourseSettingsFlags,
  addUpdateCourseSettingsFlagAction
};
